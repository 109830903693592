<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>Itens Excluídos</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="12" :sm="16">
              <el-row type="flex" justify="end">
                <el-select
                  clearable
                  v-model="selectedEntity"
                  @change="fetchDeletedItems()"
                  size="medium"
                >
                  <template #prefix>Filtrar por:</template>
                  <el-option
                    v-for="item in Entities"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="DeletedEntities"
      no-data-text="Selecione uma entidade para filtrar"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column
        prop="deleted_at"
        label="removido em"
        :formatter="(r) => dateFormatter.format(new Date(r.deleted_at))"
      >
      </el-table-column>

      <el-table-column label="ações" :width="100">
        <template #default="client">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                @confirm="restoreItem(client.row)"
                title="
              Restaurar item?
            "
                ><template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-refresh-left"></i></el-button></template
              ></el-popconfirm>
              <el-popconfirm
                @confirm="deleteItem(client.row)"
                title="
              Remover item permanentemente?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import { maska } from "maska";
export default {
  name: "UserGroups",
  data: () => ({
    hasError: false,
    isLoading: true,
    selectedEntity: null,
    createMask: maska,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "full",
      timeStyle: "long",
    }),
    deleted: null,
    entities: null,
  }),
  mounted() {
    this.fetchEntities();
  },
  methods: {
    restoreItem(e) {
      const url = new URL(`${this.$store.state.apiUrl}trash/${e.uid}`);
      url.search = new URLSearchParams({ entity: this.selectedEntity });
      fetch(url, {
        credentials: "include",
        method: "PUT",
        headers: {
          Accept: "application/json",
        },
        body: "",
      }).finally(() => this.fetchDeletedItems());
    },
    deleteItem(e) {
      const url = new URL(`${this.$store.state.apiUrl}trash/${e.uid}`);
      url.search = new URLSearchParams({ entity: this.selectedEntity });
      fetch(url, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
        },
        body: "",
      }).finally(() => this.fetchDeletedItems());
    },
    fetchEntities() {
      const url = new URL(`${this.$store.state.apiUrl}trash`);
      url.search = new URLSearchParams({ entity: this.selectedEntity });
      fetch(url, {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((r) => {
          if (r.status == "200") return r.json();
          else throw r.json();
        })
        .then((json) => (this.entities = json))
        .finally(() => (this.isLoading = false));
    },
    fetchDeletedItems() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}trash/entity`);
      url.search = new URLSearchParams({ entity: this.selectedEntity });
      fetch(url, {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((r) => {
          if (r.status == "200") return r.json();
          else throw r.json();
        })
        .then((json) => (this.deleted = json))
        .finally(() => (this.isLoading = false));
    },
    selectEntity(v) {
      this.selectedEntity = v;
    },
  },
  computed: {
    DeletedEntities() {
      return this?.deleted || [];
    },
    FilteredItems() {
      return this.selectedEntity
        ? this.items.filter((i) => i.entity === this.selectedEntity)
        : this.items;
    },
    Entities() {
      return this?.entities || [];
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>